/* eslint-disable complexity */
window.toggleGlobalModal = toggle => {
  const modal = document.querySelector('#modal')
  const modalContent = document.querySelector('#modal-content')
  document.documentElement.classList.toggle('is-modal', toggle)
  modal.classList.toggle('is-open', toggle)
  if (!toggle) modalContent.innerHTML = ''
}

const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent)
}

const shareData = {
  url: 'https://talentcentrum.sk'
}

document.addEventListener('DOMContentLoaded', () => {
  const modalButtons = document.querySelectorAll('.js-modal-open')
  if (!modalButtons?.length) return

  const modal = document.querySelector('#modal')
  const modalContent = document.querySelector('#modal-content')
  const modalCloseIcon = document.querySelector('#modal-close-icon')

  const closeModal = () => {
    window.toggleGlobalModal(false)
  }

  modal.addEventListener('click', event => {
    if (!event.target.closest('#modal-surface')) closeModal()
  })
  modalCloseIcon.addEventListener('click', closeModal)

  modalButtons.forEach(item => {
    item.addEventListener('click', async event => {
      if (
        event.target &&
        event.target.closest('.share-btn') &&
        isMobileDevice() &&
        navigator.share &&
        navigator.canShare(shareData)
      ) {
        event.stopPropagation()
        try {
          await navigator.share(shareData)
        } catch (error) {
          return null
        }
        return
      }
      const modalWrap = event.target.closest('.js-modal-wrap')
      const content = modalWrap.querySelector('.js-modal-content')
      modalContent.appendChild(content.cloneNode(true))
      window.toggleGlobalModal(true)

      const openModalEvent = new Event('openModal')
      document.dispatchEvent(openModalEvent)
    })
  })

  document.addEventListener('keydown', e => {
    if (e.key === 'Escape') closeModal()
  })
})
