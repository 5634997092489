document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('button[data-scroll-to]')

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      const targetSelector = button.getAttribute('data-scroll-to')
      const targetElement = document.querySelector(targetSelector)
      const offset = button.getAttribute('data-scroll-to-offset') || 100

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - offset,
          behavior: 'smooth'
        })
      }
    })
  })
})
