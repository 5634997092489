document.addEventListener('DOMContentLoaded', () => {
  const menuItems = document.querySelectorAll('.menu-item')
  const megaDropdown = document.querySelector('.mega-dropdown')
  const megaDropdownContent = document.querySelector('.mega-dropdown-content')
  const body = document.body
  let hoverTimeout

  const openDropdown = () => {
    clearTimeout(hoverTimeout)
    hoverTimeout = setTimeout(() => {
      megaDropdown.classList.add('active')
      body.classList.add('opened-menu')
    }, 300)
  }

  const closeDropdown = () => {
    clearTimeout(hoverTimeout)
    hoverTimeout = setTimeout(() => {
      megaDropdown.classList.remove('active')
      body.classList.remove('opened-menu')
    }, 300)
  }

  const toggleDropdown = () => {
    clearTimeout(hoverTimeout)
    if (megaDropdown.classList.contains('active')) {
      megaDropdown.classList.remove('active')
      body.classList.remove('opened-menu')
    } else {
      megaDropdown.classList.add('active')
      body.classList.add('opened-menu')
    }
  }

  menuItems.forEach(item => {
    item.addEventListener('click', toggleDropdown)
    item.addEventListener('mouseenter', openDropdown)
    item.addEventListener('mouseleave', closeDropdown)
  })

  megaDropdownContent.addEventListener('mouseenter', openDropdown)
  megaDropdownContent.addEventListener('mouseleave', closeDropdown)
})
