document.addEventListener('DOMContentLoaded', () => {
  const speed = 1 // Adjust this value to change the speed of the marquee

  function duplicateText(marqueeWrapper, marqueeContent) {
    const marqueeWidth = marqueeWrapper.offsetWidth
    let contentWidth = marqueeContent.offsetWidth
    while (contentWidth < marqueeWidth + marqueeContent.offsetWidth) {
      const clone = marqueeContent.cloneNode(true)
      marqueeWrapper.appendChild(clone)
      contentWidth += marqueeContent.offsetWidth
    }
  }

  function animateMarquee(marqueeWrapper, marqueeContent) {
    let startPosition = 0
    function step() {
      startPosition -= speed
      if (startPosition <= -marqueeContent.offsetWidth) {
        startPosition += marqueeContent.offsetWidth
        marqueeWrapper.appendChild(marqueeWrapper.firstElementChild)
      }
      marqueeWrapper.style.transform = `translateX(${startPosition}px)`
      window.requestAnimationFrame(step)
    }
    step()
  }

  const marquees = document.querySelectorAll('.marquee-container')
  marquees.forEach(marqueeContainer => {
    const marqueeWrapper = marqueeContainer.querySelector('.marquee-wrapper')
    const marqueeContent = marqueeContainer.querySelector('.marquee-content')

    duplicateText(marqueeWrapper, marqueeContent)
    animateMarquee(marqueeWrapper, marqueeContent)

    window.addEventListener('resize', () => {
      marqueeWrapper.innerHTML = '' // Clear the wrapper
      marqueeWrapper.appendChild(marqueeContent) // Add the original content back
      duplicateText(marqueeWrapper, marqueeContent) // Re-duplicate the text
      animateMarquee(marqueeWrapper, marqueeContent) // Restart the animation
    })
  })
})
