/* eslint-disable max-statements */
document.addEventListener('DOMContentLoaded', () => {
  const scrollContainers = document.querySelectorAll('.scroll-container')

  scrollContainers.forEach(scrollContainer => {
    const parentWrapper = scrollContainer.closest('.whole-screen-scroller')
    const scrollContent = scrollContainer.querySelector('.scroll-content')
    const scrollItems = scrollContent.querySelectorAll('.scroll-item')
    const circlesContainer = parentWrapper.querySelector('.circles-container')
    const scrollLeft = parentWrapper.querySelector('.scroll-left')
    const scrollRight = parentWrapper.querySelector('.scroll-right')
    const topSection = parentWrapper.querySelector('.top-section')

    let wholeScreenScrollerIsDisabled = false

    const setLeftMargin = () => {
      wholeScreenScrollerIsDisabled = window.innerWidth <= 768 && parentWrapper.classList.contains('mobile-disabled')
      const distanceFromLeft = wholeScreenScrollerIsDisabled ? 0 : topSection.getBoundingClientRect().left - 8
      scrollContent.style.marginLeft = distanceFromLeft + 'px'
    }

    setLeftMargin()
    window.addEventListener('resize', setLeftMargin)

    let isDown = false
    let startX
    let startY
    let scrollLeftStart
    let momentumSensitivity = 0.9

    scrollContainer.addEventListener('mousedown', e => {
      isDown = true
      startX = e.pageX - scrollContent.offsetLeft
      scrollLeftStart = scrollContainer.scrollLeft
      cancelMomentumTracking()
    })

    scrollContainer.addEventListener('mouseleave', () => {
      isDown = false
    })

    scrollContainer.addEventListener('mouseup', () => {
      isDown = false
      momentumSensitivity = 0.75
      beginMomentumTracking()
    })

    scrollContainer.addEventListener('mousemove', e => {
      if (!isDown || wholeScreenScrollerIsDisabled) return
      e.preventDefault()
      const xPos = e.pageX - scrollContent.offsetLeft - startX
      const prevScrollLeft = scrollContainer.scrollLeft
      scrollContainer.scrollLeft = scrollLeftStart - xPos
      velX = scrollContainer.scrollLeft - prevScrollLeft
    })

    scrollContainer.addEventListener('touchstart', e => {
      isDown = true
      startX = e.touches[0].pageX - scrollContent.offsetLeft
      scrollLeftStart = scrollContainer.scrollLeft
      startY = e.touches[0].pageY
      cancelMomentumTracking()
    })

    scrollContainer.addEventListener('touchend', () => {
      isDown = false
      momentumSensitivity = 0.92
      beginMomentumTracking()
    })

    scrollContainer.addEventListener('touchmove', e => {
      if (!isDown || wholeScreenScrollerIsDisabled) return
      const xPos = e.touches[0].pageX - scrollContent.offsetLeft - startX
      const yPos = e.touches[0].pageY - startY

      if (Math.abs(xPos) > Math.abs(yPos)) {
        e.preventDefault()
        const prevScrollLeft = scrollContainer.scrollLeft
        scrollContainer.scrollLeft = scrollLeftStart - xPos
        velX = scrollContainer.scrollLeft - prevScrollLeft
      }
    })

    // ------ Momentum ------
    let velX = 0
    let momentumID

    function beginMomentumTracking() {
      cancelMomentumTracking()
      momentumID = window.requestAnimationFrame(momentumLoop)
    }
    function cancelMomentumTracking() {
      window.cancelAnimationFrame(momentumID)
    }
    function momentumLoop() {
      scrollContainer.scrollLeft += velX
      velX *= momentumSensitivity
      if (Math.abs(velX) > 0.5) {
        momentumID = window.requestAnimationFrame(momentumLoop)
      }
    }
    // ----------------------

    // Handle horizontal scrolling with touchpad or horizontal mouse wheel
    scrollContainer.addEventListener('wheel', e => {
      cancelMomentumTracking()

      if (e.deltaX === 0 || !(Math.abs(e.deltaX / e.deltaY) > 1.2) || wholeScreenScrollerIsDisabled) return // Only handle horizontal scrolling
      e.preventDefault()
      scrollContainer.scrollLeft += e.deltaX
    })

    const itemWidth =
      scrollContent.querySelector('.scroll-item').offsetWidth +
      parseFloat(window.getComputedStyle(scrollContent.querySelector('.scroll-item')).marginRight) * 2

    // Arrow click events
    scrollLeft.addEventListener('click', () => {
      scrollContainer.scrollBy({
        top: 0,
        left: -itemWidth, // Adjust the value for desired scroll amount
        behavior: 'smooth'
      })
    })

    scrollRight.addEventListener('click', () => {
      scrollContainer.scrollBy({
        top: 0,
        left: itemWidth, // Adjust the value for desired scroll amount
        behavior: 'smooth'
      })
    })

    // Create circles based on the number of scroll items
    scrollItems.forEach((item, index) => {
      const circle = document.createElement('div')
      circle.classList.add('circle')
      circle.addEventListener('click', () => {
        scrollContainer.scrollTo({
          top: 0,
          left: itemWidth * index,
          behavior: 'smooth'
        })
      })
      circlesContainer.appendChild(circle)
    })

    const updateActiveCircle = () => {
      const currentIndex = Math.round(scrollContainer.scrollLeft / itemWidth)
      const circles = circlesContainer.querySelectorAll('.circle')

      circles.forEach((circle, index) => {
        if (index === currentIndex) {
          circle.classList.add('active')
        } else {
          circle.classList.remove('active')
        }
      })
    }

    // Update arrow buttons state
    const updateScrollButtons = () => {
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth

      if (scrollContainer.scrollLeft === 0) scrollLeft.classList.add('disabled')
      else scrollLeft.classList.remove('disabled')

      if (scrollContainer.scrollLeft >= maxScrollLeft) scrollRight.classList.add('disabled')
      else scrollRight.classList.remove('disabled')

      updateActiveCircle()
    }

    scrollContainer.addEventListener('scroll', updateScrollButtons)
    updateScrollButtons()
    updateActiveCircle()
  })
})
