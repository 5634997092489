let openedDropdown = null
const toggleVisibility = e => {
  e.classList.toggle('show')
}

const handleDropdown = e => {
  const clickedItem = e.parentElement
  toggleVisibility(clickedItem)
  if (!openedDropdown) {
    openedDropdown = clickedItem
  } else if (openedDropdown === clickedItem) {
    openedDropdown = null
  } else {
    toggleVisibility(openedDropdown)
    openedDropdown = clickedItem
  }
}

const handleClick = e => {
  let element = e.target
  if (!element?.parentElement) return
  let hasDropdownClass
  if (element.parentElement.classList.contains('dropdown-button')) {
    hasDropdownClass = true
    element = element.parentElement
  } else {
    hasDropdownClass = element.classList.contains('dropdown-button')
  }
  if (hasDropdownClass) {
    handleDropdown(element)
  } else if (openedDropdown) {
    toggleVisibility(openedDropdown)
    openedDropdown = null
  }
}

document.addEventListener('click', handleClick)
