document.addEventListener('DOMContentLoaded', () => {
  const dropdownComponents = document.querySelectorAll('.dropdown-component')

  dropdownComponents.forEach(component => {
    const button = component.querySelector('.dropdown-trigger')

    button.addEventListener('click', event => {
      event.preventDefault()
      // Check if this component is already active
      const isActive = component.classList.contains('active')
      closeAllDropdowns()
      // Toggle this component based on its previous state
      if (!isActive) {
        component.classList.add('active')
      }
    })

    document.addEventListener('click', event => {
      if (!component.contains(event.target)) {
        component.classList.remove('active')
      }
    })
  })

  function closeAllDropdowns() {
    dropdownComponents.forEach(component => {
      component.classList.remove('active')
    })
  }
})
