document.addEventListener('DOMContentLoaded', () => {
  const collapsibles = document.querySelectorAll('.collapsible')

  collapsibles.forEach(collapsible => {
    const header = collapsible.querySelector('.collapsible-header')
    header.addEventListener('click', () => {
      collapsible.classList.toggle('active')
    })
  })
})
