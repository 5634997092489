document.addEventListener('DOMContentLoaded', () => {
  const buttonContainer = document.querySelector('.id-modal-content')

  buttonContainer.addEventListener('click', event => {
    if (event.target && event.target.classList.contains('copy-button')) {
      const copyInput = buttonContainer.querySelector('.copy-input')

      if (copyInput) {
        copyInput.focus()
        copyInput.select()
        copyInput.setSelectionRange(0, 99999)

        // Attempt to copy the text
        navigator.clipboard
          .writeText(copyInput.value)
          // eslint-disable-next-line promise/always-return
          .then(() => {
            showMessage('Success', 'success')
          })
          .catch(() => {
            showMessage('Failed to copy, please try manually.', 'error')
          })
      } else {
        console.error('Copy input not found.')
      }
    }
  })

  function showMessage(text, type) {
    const messageContainer = buttonContainer.querySelector('.share-content-modal_info')
    if (messageContainer) {
      messageContainer.textContent = text
      messageContainer.classList.add('visible', type)
    }

    setTimeout(() => {
      if (messageContainer) {
        messageContainer.classList.remove('visible', type)
      }
    }, 3000)
  }
})
