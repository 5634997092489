const serialize = data => {
  const values = {}
  for (const [key, value] of data) {
    if (values[key] !== undefined) {
      if (!Array.isArray(values[key])) {
        values[key] = [values[key]]
      }
      // eslint-disable-next-line fp/no-mutating-methods
      values[key].push(value)
    } else {
      values[key] = value
    }
  }
  return values
}

document.addEventListener('DOMContentLoaded', () => {
  // Feedback forms
  document.querySelectorAll('.input-subject').forEach(item => {
    item.style.display = 'none'
  })

  const onSendResponse = form => response => {
    if (response.status >= 400) {
      form.querySelector('.error-message').style.display = 'block'
    } else {
      form.querySelectorAll('input, textarea').forEach(input => {
        input.value = ''
      })
      form.querySelector('.success-message').style.display = 'block'
    }
    form.querySelector('button').removeAttribute('disabled')
    return response
  }

  document.querySelectorAll('.feedback-forms form').forEach(form => {
    form.addEventListener('submit', event => {
      event.preventDefault()
      event.target.querySelector('.success-message').style.display = 'none'
      event.target.querySelector('.error-message').style.display = 'none'
      event.target.querySelector('button').setAttribute('disabled', 'disabled')
      try {
        const values = serialize(new window.FormData(event.target))
        fetch(`https://service.bite.social/wibo/contact.php`, {
          method: 'POST',
          body: JSON.stringify({
            user: {
              name: values.name,
              email: values.email,
              messageBody: values.messageBody,
              language: values.language,
              surname: values.surname,
              company: values.company,
              phone: values.phone,
              subject: values.subject
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            token: 'akjhgi4309iortklfsfldkjg0239opek'
          }
        })
          .then(onSendResponse(event.target))
          .catch(error => {
            console.error(error)
            event.target.querySelector('.error-message').style.display = 'block'
            event.target.querySelector('button').removeAttribute('disabled')
          })
      } catch (e) {
        console.error(e)
        event.target.querySelector('.error-message').style.display = 'block'
        event.target.querySelector('button').removeAttribute('disabled')
      }
    })
  })
})
