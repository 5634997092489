/* eslint-disable complexity */
document.addEventListener('DOMContentLoaded', () => {
  const mobileHeader = document.getElementById('sticky-mobile-header')
  const mobileStickyOffset = mobileHeader.offsetTop
  const desktopHeader = document.getElementById('sticky-desktop-header')
  const mainElement = document.querySelector('.main-content')

  function stickMenu() {
    if (window.scrollY > mobileStickyOffset && window.getComputedStyle(mobileHeader.parentElement).display !== 'none') {
      mobileHeader.classList.add('sticky')
      mobileHeader.parentElement.style.paddingTop = `${mobileHeader.offsetHeight}px`
    } else {
      mobileHeader.classList.remove('sticky')
      mobileHeader.parentElement.style.paddingTop = '0px'
    }
    const marqueeElement = document.getElementById('marquee-wrapper')
    // TODO: 32 is the height of the marquee line in the top of the page
    const topOffset = marqueeElement ? 32 : 0
    if (window.scrollY > topOffset && window.getComputedStyle(desktopHeader).display !== 'none') {
      desktopHeader.parentElement.classList.add('sticky')
      desktopHeader.parentElement.nextElementSibling.style.marginTop = `${desktopHeader.offsetHeight}px`
      mainElement.style.marginTop = `${desktopHeader.offsetHeight}px`
    } else {
      desktopHeader.parentElement.classList.remove('sticky')
      desktopHeader.parentElement.nextElementSibling.style.marginTop = `${desktopHeader.offsetHeight}px`
      mainElement.style.marginTop = `${desktopHeader.offsetHeight}px`
    }
  }

  stickMenu()
  window.onscroll = stickMenu
})
