/* eslint-disable max-nested-callbacks */
const cookieValues = {
  analyticalDeclined: window.localStorage?.getItem('analytical-declined')
    ? window.localStorage?.getItem('analytical-declined') === 'true'
    : true,
  statisticalDeclined: window.localStorage?.getItem('statistical-declined')
    ? window.localStorage?.getItem('statistical-declined') === 'true'
    : true,
  marketingDeclined: window.localStorage?.getItem('marketing-declined')
    ? window.localStorage?.getItem('marketing-declined') === 'true'
    : true
}

const saveCookiesChanges = () => {
  const cookieNotice = document.querySelector('.important-notice')
  cookieNotice?.remove()
  window.localStorage?.setItem('read-important-notice', 'true')
  window.localStorage?.setItem('analytical-declined', Boolean(cookieValues.analyticalDeclined).toString())
  window.localStorage?.setItem('statistical-declined', Boolean(cookieValues.statisticalDeclined).toString())
  window.localStorage?.setItem('marketing-declined', Boolean(cookieValues.marketingDeclined).toString())
  window.toggleGlobalModal(false)
  const saveChangesButtons = document.querySelectorAll('.save-customized-important-notice')
  saveChangesButtons?.forEach(saveChangesButton => saveChangesButton?.classList.remove('filled'))

  window.setConsentGranted?.({
    ad_user_data: cookieValues.marketingDeclined ? 'denied' : 'granted',
    ad_personalization: cookieValues.marketingDeclined ? 'denied' : 'granted',
    ad_storage: cookieValues.marketingDeclined ? 'denied' : 'granted',
    analytics_storage: cookieValues.analyticalDeclined ? 'denied' : 'granted'
  })
}

const allowAllCookies = () => {
  cookieValues.analyticalDeclined = false
  cookieValues.statisticalDeclined = false
  cookieValues.marketingDeclined = false
  saveCookiesChanges()
}

const declineAllCookies = () => {
  cookieValues.analyticalDeclined = true
  cookieValues.statisticalDeclined = true
  cookieValues.marketingDeclined = true
  saveCookiesChanges()
}

const cookieValuesListener = resetToFalse => () => {
  // Show important-notice bar if it is not saved yet
  const cookieNotice = document.querySelector('.important-notice')
  const isRead = window.localStorage?.getItem('read-important-notice') === 'true'
  // If message is not read, show it on screen
  if (cookieNotice && !isRead) cookieNotice.removeAttribute('style')

  const saveChangesButtons = document.querySelectorAll('.save-customized-important-notice')
  saveChangesButtons?.forEach(item => item.addEventListener('click', saveCookiesChanges))

  const acceptAllButtons = document.querySelectorAll('.accept-all-important-notice')
  acceptAllButtons?.forEach(item => item.addEventListener('click', allowAllCookies))

  const declineAllButtons = document.querySelectorAll('.decline-all-important-notice')
  declineAllButtons?.forEach(item => item.addEventListener('click', declineAllCookies))

  if (document.querySelectorAll('.slider-toggle')?.length) {
    const sliderToggleItems = document.querySelectorAll('.slider-toggle')
    // eslint-disable-next-line complexity
    sliderToggleItems.forEach(item => {
      // Predefined values
      if (resetToFalse) {
        item.classList.remove('active')
        cookieValues.analyticalDeclined = true
        cookieValues.statisticalDeclined = true
        cookieValues.marketingDeclined = true
      } else {
        if (item.dataset.id === 'toggle-analytical' && cookieValues.analyticalDeclined) {
          item.classList.remove('active')
        } else if (item.dataset.id === 'toggle-statistical' && cookieValues.statisticalDeclined) {
          item.classList.remove('active')
        } else if (item.dataset.id === 'toggle-marketing' && cookieValues.marketingDeclined) {
          item.classList.remove('active')
        }
      }

      // Do not add second event listener to cookie-bar togglers
      if (resetToFalse && item.classList.contains('front-panel')) return

      // Event listener to change values
      item.addEventListener('click', () => {
        saveChangesButtons?.forEach(saveChangesButton => {
          // on cookie-bar show "save" button instead of "allow all" after any change
          if (saveChangesButton?.classList.contains('bar-button')) {
            saveChangesButton.removeAttribute('style')
            acceptAllButtons?.forEach(acceptAllButton => {
              if (acceptAllButton?.classList.contains('bar-button')) {
                acceptAllButton.remove()
              }
            })
          } else {
            saveChangesButton?.classList.add('filled')
          }
        })
        const isActive = !item.classList.contains('active')
        item.classList.toggle('active')

        // Toggle Manage Cookies sliders
        if (item.dataset.id === 'toggle-analytical') cookieValues.analyticalDeclined = !isActive
        else if (item.dataset.id === 'toggle-statistical') cookieValues.statisticalDeclined = !isActive
        else if (item.dataset.id === 'toggle-marketing') cookieValues.marketingDeclined = !isActive
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', cookieValuesListener())
document.addEventListener('openModal', cookieValuesListener(true))
