/* eslint-disable max-nested-callbacks */
const normalizeString = text => {
  if (!text) return ''
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

document.addEventListener('DOMContentLoaded', () => {
  const filterContent = document.querySelector('.filter-content')
  const filterButtons = document.querySelectorAll('.filter-toggle')

  if (!filterContent || !filterButtons) return

  const hideAllItems = () => {
    filterContent
      .querySelectorAll('[data-category]')
      .forEach(filteredElement => filteredElement.classList.remove('visible'))
  }

  const showAllItems = () => {
    filterButtons.forEach(button => button.classList.remove('active'))
    filterContent
      .querySelectorAll('[data-category]')
      .forEach(filteredElement => filteredElement.classList.add('visible'))
  }

  // Filter Buttons
  filterButtons.forEach(item => {
    item.addEventListener('click', event => {
      const currentFilter = item.getAttribute('data-category')
      const parentElement = event.target.parentNode
      parentElement.querySelectorAll('.filter-toggle').forEach(button => button.classList.remove('active'))
      item.classList.add('active')

      document.querySelector('.filter-search .search-input').value = ''
      hideAllItems()
      filterContent.querySelectorAll(`[data-category='${currentFilter}']`).forEach(filteredElement => {
        filteredElement.classList.add('visible')
      })
    })
  })

  // Search by text
  document.querySelectorAll('form.filter-search').forEach(filterSearchForm => {
    filterSearchForm.addEventListener('submit', event => {
      event.preventDefault()
      const formData = new window.FormData(event.target)
      const value = formData.get('search')
      const normalizedValue = normalizeString(value?.trim())
      // If there is a search value, remove all category filters
      if (normalizedValue) {
        showAllItems()
        const allItems = Array.from(filterContent.children)
        // Hide all items, which do not have the search string in text
        allItems.forEach(element => {
          const title = normalizeString(element.querySelector('.accordion-button')?.innerText)
          const text = normalizeString(element.querySelector('.accordion-content > div')?.innerText)
          const tagName = element.tagName
          if (tagName === 'H3') return
          if (!title.includes(normalizedValue) && !text.includes(normalizedValue)) element.classList.remove('visible')
        })
        // Hide section titles without children
        filterContent.querySelectorAll('h3[data-category]').forEach(element => {
          const category = element.getAttribute('data-category')
          const visibleCategoryElements = filterContent.querySelectorAll(
            `.accordion-item[data-category='${category}'].visible`
          )
          if (!visibleCategoryElements?.length) element.classList.remove('visible')
        })
        // If there search value is empty, show first category again
      } else {
        filterButtons[0].click()
      }
    })
  })

  // Init by selecting first category
  filterButtons[0].click()
})
