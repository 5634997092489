const hamburgerClickHandler = () => {
  document.documentElement.classList.add('is-mobile-menu')

  const updateMenuBottomPosition = () => {
    const menuBottom = document.querySelector('.menu-bottom')
    window.requestAnimationFrame(() => {
      const viewportHeight = window.innerHeight
      const menuBottomHeight = menuBottom.getBoundingClientRect().height
      const newBottomPosition = Math.max(0, viewportHeight - menuBottomHeight)
      menuBottom.style.top = `${newBottomPosition}px`
    })
  }
  updateMenuBottomPosition()
  window.addEventListener('resize', updateMenuBottomPosition)
}

const mobileMenuCloseHandler = () => {
  document.documentElement.classList.remove('is-mobile-menu')
}

document.addEventListener('DOMContentLoaded', () => {
  const hamburgerButton = document.querySelector('.hamburger-button')
  hamburgerButton?.addEventListener('click', hamburgerClickHandler)
  const mobileMenuCloseButton = document.querySelector('.mobile-menu-close-button')
  mobileMenuCloseButton?.addEventListener('click', mobileMenuCloseHandler)
})
